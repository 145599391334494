import { observer } from 'mobx-react';
import { useEffect } from 'react';

import Button from 'components/UI/Button/Button';
import Plate from 'components/UI/Plate/Plate';
import CheckBox from 'components/UI/CheckBox/CheckBox';

import messageBoxStore from './MessageBoxStore';
import AppStore from 'AppStore';

import './MessageBox.scss';

export const MessageBox = observer(() => {
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 27) {
        messageBoxStore.close();
      }
    };

    const handleClickOverlay = (e) => {
      if (e.target.classList.contains('messagebox')) {
        messageBoxStore.close();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClickOverlay);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClickOverlay);

    ;}
  }, [])

  const getIconClass = () => {
    const locationHash = window.location.hash;
    
    if (locationHash === '#success') {
      return 'messagebox__icon_success';
    }
    
    return `messagebox__icon_${messageBoxStore.status}`;
  };

  return (
    <div className={'messagebox' + (messageBoxStore.isOpen ? ' messagebox_opened' : '')}>
      <Plate className='messagebox__plate'>
        <div className='messagebox__container'>
          <div className='messagebox__close-icon' onClick={() => { messageBoxStore.close() }} />

          {messageBoxStore.status && <div className={`messagebox__icon ${getIconClass()}`} />}

          {messageBoxStore.message && <div className='messagebox__message'>{messageBoxStore.message}</div>}
          {messageBoxStore.comment && <div className='messagebox__comment'>{messageBoxStore.comment}</div>}
        </div>
        {messageBoxStore.showCheckbox && (
          <CheckBox
            title="Больше не показывать"
            checked={messageBoxStore.checkboxChecked}
            onClick={() => messageBoxStore.setCheckboxChecked(!messageBoxStore.checkboxChecked)}
          />
        )}
        <Button title={messageBoxStore.actionTitle} onClick={() => { if (messageBoxStore.showCheckbox) AppStore.setHidePaymentNotification(messageBoxStore.checkboxChecked); if (messageBoxStore.action) messageBoxStore.action(); messageBoxStore.close() }} />
      </Plate>
    </div>
  );
});
