import { Link } from "react-router-dom";

import Banner from "components/UI/Banner/Banner";
import Button from "components/UI/Button/Button";
import InfoPoint from "components/UI/InfoPoint/InfoPoint";

import popupStore from "components/Popup/PopupStore";
import messageBoxStore from "MessageBox/MessageBoxStore";

import dinoBudget from 'assets/images/dino/dino-budget.png';
import dinoModal from 'assets/images/dino/dino-modal.png';
import dinoProjectLeft from 'assets/images/dino/dino-project-left.png';
import dinoProjectRight from 'assets/images/dino/dino-project-right.png';
import dinoStep1 from 'assets/images/dino/dino-step1.png';
import dinoStep2 from 'assets/images/dino/dino-step2.png';
import dinoStep3 from 'assets/images/dino/dino-step3.png';
import dinoStep1Mini from 'assets/images/dino/dino-step1-mini.png';
import dinoStep2Mini from 'assets/images/dino/dino-step2-mini.png';
import dinoStep3Mini from 'assets/images/dino/dino-step3-mini.png';
import playIcon from 'assets/images/play.svg';

import "./BannerStep.scss";

const BannerStep = ({
  appStore,
  banner,
  stepPhrase1,
  stepPhrase2,
  onClick,
  isMobile
}) => {

  const toggleTelegramNotifications = async () => {
    popupStore.open('bot-modal');
    if (!appStore.telegramId) {
      try {
        await appStore.turnOnNotifications();
      } catch (error) {
        messageBoxStore.showError(error || "Произошла ошибка при формировании кода");
      }
    }
  };

  const stepContent = {
    'banner-first-step': {
      image: isMobile ? dinoStep1Mini : dinoStep1,
      imagePosition: "left",
      content: (
        <div className="banner-step">
          <p className="banner-step__title">1<span>/2</span> <span>Начнём создание проекта?</span></p>
          <div className="banner-step__info_points">
            <InfoPoint icon={playIcon} text={<p><span>Выберите площадку</span> для продвижения или проверьте уже выбранную площадку</p>} />
            <InfoPoint icon={playIcon} text={<h2>{stepPhrase1}</h2>} />
          </div>
        </div>
      )
    },
    'banner-second-step': {
      image: isMobile ? dinoStep2Mini : dinoStep2,
      imagePosition: isMobile ? "left" : "right",
      content: (
        <div className="banner-step">
          <p className="banner-step__title">2<span>/2 </span><span>Осталось совсем немного</span></p>
          <div className="banner-step__info_points">
            <InfoPoint icon={playIcon} text={<h2>{stepPhrase2}, <span>чтобы исполнителям было проще его найти</span></h2>} />
            <InfoPoint icon={playIcon} text={<p>И не забудьте заполнить поля поисковой фразы.<span> Это очень важно!</span></p>} />
          </div>
        </div>
      )
    },
    'banner-third-step': {
      image: isMobile ? dinoStep3Mini : dinoStep3,
      imagePosition: "left",
      content: (
        <div className="banner-step">
          <p className={`banner-step__title ${banner}`}>Запуск!</p>
          <p className="banner-step__text">Всё готово к запуску? Проверь ещё раз, всё ли верно и <span>жми кнопку «Создать проект»</span></p>
          <Button title="Создать проект" onClick={onClick} />
        </div>
      )
    },
    'banner-budget': {
      image: dinoBudget,
      imagePosition: "right",
      content: (
        <div className="banner-step">
          <p className="banner-step__text">Ниже показана <span>общая стоимость</span> и <span>скорость отправки задания в работу</span></p>
        </div>
      )
    },
    'banner-modal': {
      image: dinoModal,
      imagePosition: "right",
      content: (
        <div className="banner-step">
          <p className="banner-step__text">Для комфортной работы на платформе, просим <span>внимательно прочитать правила и условия.</span></p>
        </div>
      )
    },
    'banner-project-left': {
      image: dinoProjectLeft,
      imagePosition: "left",
      content: (
        <div className="banner-step">
          <p className={`banner-step__title ${banner}`}>Не забывайте регулярно проверять отчеты исполнителей!</p>
          <p className="banner-step__text">Контроль — залог успеха! Следите за прогрессом и своевременно вносите корректировки.</p>
        </div>
      )
    },
    'banner-project-right': {
      image: dinoProjectRight,
      imagePosition: "right",
      content: (
        <div className="banner-step">
          <p className={`banner-step__title ${banner}`}>Для оперативного получения уведомлений о новых отчетах, подключите Telegram-бота! </p>
          <p className="banner-step__text">Это сэкономит ваше время и позволит реагировать на изменения моментально. <Link className="banner-step__link" onClick={toggleTelegramNotifications}>Подключить бота</Link></p>
        </div>
      )
    }
  };
  const currentStep = stepContent[banner];

  if (!currentStep) return null;

  return (
    <Banner
      image={currentStep.image}
      imagePosition={currentStep.imagePosition}
      content={currentStep.content}
      className={banner}
    />
  );
};

export default BannerStep;
