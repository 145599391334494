import assert from 'browser-assert';

import { Fragment, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";

import Main from "pages/Main/Main";
import Market from "pages/markets/Market/Market";

import { Projects } from "pages/Projects/Projects";
import Footer from "./Footer/Footer";
import Blog from "pages/Blog/Blog";
import Article from "pages/Blog/Article/Article";
import NotFoundPages from "pages/NotFoundPages/NotFoundPages";
import { Referral } from "pages/Referral/Referral";

import articlesData from "pages/Blog/Article/articlesData";

import AppStore from "AppStore";
import messageBoxStore from 'MessageBox/MessageBoxStore';

import { SponsorId } from "domain/id";

import "./AppWithFooter.scss";

export const AppWithFooter = observer(({ sponsorId }) => {
  assert(!sponsorId || (sponsorId instanceof SponsorId));

  useEffect(() => {
    if (sponsorId && !AppStore.rightNowRegistered) {
      messageBoxStore.showInfo(
        'Информация',
        'Стать рефералом может только новый пользователь'
      );
    }
  }, [sponsorId]);

  return (
    <div className="app-with-footer">
      <div className="app-with-footer__content">
        <Routes>
          <Route path="/" element={(sponsorId && !AppStore.rightNowRegistered) ? <Referral /> : <Main />} />

          {/* Create routes for all calculators */}
          {AppStore.marketStores.map((marketStore) => (
            <Fragment key={marketStore}>
              {marketStore.calculators.map(calculator => (
                <Route key={calculator} path={calculator.mode.path} element={<Market />} />
              ))}
            </Fragment>
          ))}

          <Route path="/blog" element={<Blog />} />
          {articlesData.map((article, index) => (
            <Route key={index} path={`/blog/${article.id}`} element={<Article id={article.id} />} />
          ))}

          {AppStore.isAuth && (
            <>
              <Route path="/projects">
                <Route index element={<Projects />} />
              </Route>
            </>
          )}
          <Route path="/referral" element={AppStore.isAuth ? <Referral /> : <Navigate to="/signin" replace />} />

          <Route path="*" element={<NotFoundPages />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
});
