import {
  HEADER_ACTIONS_ICONS,
  ACTION_ICONS,
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market,
  HOME_ACTION_ICONS
} from "../market";

import yandexmarketIcon from "assets/images/MarketLogo/yandexmarket.svg";
import yandexmarketDisabledIcon from "assets/images/MarketLogo/disabled/yandexmarket.svg";
import yandexmarketLogo from "assets/images/MarketFullLogo/yandexmarket.svg";

const YANDEXMARKET_FAQ = [
  {
    title: "Как работает продвижение товара на Яндекс Маркете?",
    isH3: true,
    text: (
      <p>
        <b>Поведенческие факторы</b> — это данные о том, как пользователи взаимодействуют с вашим товаром: клики, просмотры, добавления в корзину, отзывы и т. д.
        Чем больше действий, тем выше ваш товар в поиске.
        <br />
        <br />
        <b>Поисковая оптимизация:</b>
        <br />
        <b>Ключевые слова:</b> Правильный выбор и использование ключевых слов в названии товара, описании, характеристиках и тегах значительно влияет на его ранжирование в выдаче поиска.<br />
        <b>Оптимизация карточки товара:</b> Создайте привлекательное описание, добавьте качественные изображения, укажите все важные характеристики, чтобы максимально полно информировать покупателя.
        <br />
        <br />
        <b>Реклама:</b>
        <br />
        <b>Яндекс Директ:</b> Размещайте контекстную рекламу, чтобы показывать ваш товар потенциальным покупателям, которые ищут похожие товары в Яндексе.
        <br />
        <br />
        <b>Специальные предложения:</b>
        <br />
        <b>Скидки и акции:</b> Проводите скидки, участвуйте в акциях Яндекс Маркета, чтобы сделать ваш товар более привлекательным.<br />
        <b>Бесплатная доставка:</b> Предлагайте бесплатную доставку, чтобы повысить конкурентоспособность.
      </p>
    ),
  },
  {
    title: "Как Boostclick помогает продвигать товары на Яндекс Маркете?",
    isH3: false,
    text:
      <p>
        Boostclick помогает влиять на поведенческие факторы на Яндекс Маркете за счет выполнения заданий, которые делают исполнители.
        Яндекс Маркет видит активность по вашим товарам от реальных пользователей, сравнивает ее с активностью других товаров в вашей категории.
        На основе этой и другой информации делает выводы, какой товар показывать чаще и на каких позициях.
      </p>
  },
  {
    title: "Что нужно, чтобы подключить ПФ (Поведенческие Факторы) на свои товары?",
    isH3: true,
    text:
      <p>
        1. Создайте проект на сервисе Boostclick.
        <br />
        2. Укажите ключевые запросы, по которым хотите, чтобы находили ваш товар.
        <br />
        3. Укажите параметры, по которым исполнитель сможет найти ваш товар.
        <br />
        4. После получения отчета проверьте ответы исполнителя и убедитесь, что он нашел верную карточку.
        <br />
        5. Если задание сделано верно, то примите его. Если задание сделано неверно, то отправьте на доработку или смените исполнителя.
      </p>
  },
  {
    title: "Как быстро я увижу результат от ПФ продвижения?",
    isH3: true,
    text:
      <p>
        Первые результаты вы можете увидеть уже в течение нескольких дней после запуска кампании.
        Однако, для достижения стабильного эффекта требуется время.
        <br />
        <br />
        <b>Внимание:</b>
        В каждой категории могут отличаться параметры и объемы ПФ, каждый маркетолог сам определяет, как и в каком объеме пользоваться сервисом.
      </p>
  },
  {
    title: "Зачем надо проверять исполнителей при работе с ПФ Яндекс Маркета?",
    isH3: false,
    text:
      <p>
        Исполнители — это абсолютно разные люди, которые ищут онлайн-заработок.
        К сожалению, не все исполнители относятся серьезно к заданиям, и бывают случаи, когда задание могут взять, но не сделать.
        Чтобы избежать таких ситуаций, необходима модерация.
      </p>
  },
];

const STEP_TWO_TITLE_FAQ = (
  <p>
    Почему при добавлении одного действия в калькуляторе появляются
    дополнительные действия?
    <br />
    <br />
    Сервис формирует уникальные сценарии для каждого исполнителя, и каждый
    сценарий начинается для нового исполнителя с поиска товара по сделанному
    скриншоту вашей ссылки.
  </p>
);

const REVIEW_PRICE = 250;

const ACTIONS = [
  {
    type: "MAIN.FAVORITE",
    icon: ACTION_ICONS.HEART,
    iconHome: HOME_ACTION_ICONS.HOME_HEART,
    title: "Добавить товар в избранное",
    initialShare: 0.5,
    activityType: 'Избранное',
  },
  {
    type: "MAIN.BUSKET",
    icon: ACTION_ICONS.BUSKET,
    iconHome: HOME_ACTION_ICONS.HOME_BUSKET,
    title: "Добавить товар в корзину",
    initialShare: 0.1,
    activityType: 'Корзина',
  },
  {
    type: "MAIN.BUY",
    icon: ACTION_ICONS.BUSKET,
    iconHome: HOME_ACTION_ICONS.HOME_BUSKET,
    title: "Нажать «Купить сейчас»",
    initialShare: 0.2,
    activityType: 'Корзина',
  },
  {
    type: "MAIN.LIKE",
    icon: ACTION_ICONS.LIKE,
    iconHome: HOME_ACTION_ICONS.HOME_LIKE,
    title: "Поставить лайк на последний отзыв",
    initialShare: 0.1,
    activityType: 'Лайк',
  },

  {
    type: "ALWAYS.OPEN",
    icon: ACTION_ICONS.OPEN,
    iconHome: HOME_ACTION_ICONS.HOME_OPEN,
    title: "Открыть сайт или приложение Яндекс Маркет",
    initialShare: 1,
  },
  {
    type: "ALWAYS.SEARCH",
    icon: ACTION_ICONS.SEARCH,
    iconHome: HOME_ACTION_ICONS.HOME_SEARCH,
    title: "Найти товар через поиск Яндекс Маркета",
    initialShare: 1,
  },
  {
    type: 'ALWAYS.COMPETITORS',
    icon: ACTION_ICONS.TIME,
    iconHome: HOME_ACTION_ICONS.HOME_TIME,
    title: 'Быстро посмотреть конкурентов',
    initialShare: 1,
  },
  {
    type: "ALWAYS.CLICK",
    icon: ACTION_ICONS.CLICK,
    iconHome: HOME_ACTION_ICONS.HOME_CLICK,
    title: "Зайти на карточку товара",
    initialShare: 1,
  },

  {
    type: "EXTRA.MEDIA",
    icon: ACTION_ICONS.EYE,
    iconHome: HOME_ACTION_ICONS.HOME_EYE,
    title: "Посмотреть все фото и видео на карточке",
    initialShare: 0.6,
  },
  {
    type: "EXTRA.DESCRIPTION",
    icon: ACTION_ICONS.GLASSES,
    iconHome: HOME_ACTION_ICONS.HOME_GLASSES,
    title: "Прочитать полное описание товара",
    initialShare: 0.2,
  },
  {
    type: "EXTRA.GOODS",
    icon: ACTION_ICONS.CLICK,
    iconHome: HOME_ACTION_ICONS.HOME_CLICK,
    title: "Нажать кнопку «Все товары продавца»",
    initialShare: 0.1,
  },
  {
    type: "EXTRA.FEEDBACKS",
    icon: ACTION_ICONS.GLASSES,
    iconHome: HOME_ACTION_ICONS.HOME_GLASSES,
    title: "Прочитать отзывы на товар",
    initialShare: 0.1,
  },

  {
    type: "MAIN.THUMBSUP",
    icon: ACTION_ICONS.LIKE,
    iconHome: HOME_ACTION_ICONS.HOME_LIKE,
    title: "Поставить палец вверх на последний вопрос о товаре",
    initialShare: 0.1,
    activityType: 'Лайк',
  },
  {
    type: "MAIN.DISLIKE",
    icon: ACTION_ICONS.DISLIKE,
    iconHome: HOME_ACTION_ICONS.HOME_DISLIKE,
    title: "Поставить дизлайк на последний вопрос о товаре",
    initialShare: 0,
    activityType: 'Дизлайк',
  },
];

const STEP_PHRASE_2 = "Укажите информацию для поиска товара";

const YANDEXMARKET_KEYWORDS = [
  'Yandex Market', 'yandex market', 'Яндекс Маркет', 'яндекс маркет', 'ям', 'zv'
];

class YandexMarketClass extends Market {
  id = "yandexmarket";
  host = "market.yandex.ru";
  url = "https://market.yandex.ru/";

  title = "Яндекс Маркет";

  isMarketplace = true;

  icon = yandexmarketIcon;
  disabledIcon = yandexmarketDisabledIcon;
  logo = yandexmarketLogo;
  titlePlaces = "Я.Маркет";
  statusForHomePage = MARKET_MODE_STATUS.NEW;

  categories = [
    { type: "Одежда и обувь" },
    { type: "Дом" },
    { type: "Детские товары" },
    { type: "Красота" },
    { type: "Электроника" },
    { type: "Бытовая техника" },
    { type: "Цветы" },
    { type: "Дача и сад" },
    { type: "Продукты питания" },
    { type: "Строительство и ремонт" },
    { type: "Мебель" },
    { type: "Автотовары" },
    { type: "Аптека" },
    { type: "Спорт и отдых" },
    { type: "Товары ИКЕА" },
    { type: "Ювелирные украшения" },
    { type: "Книги" },
    { type: "Хобби и творчество" },
    { type: "Досуг и развлечения" },
    { type: "Для школы и офиса" },
    { type: "Ноутбуки и компьютеры" },
    { type: "Все для гейминга" },
    { type: "Умные колонки" },
    { type: "Гигиена" },
    { type: "Бытовая химия" },
    { type: "Зоотовары" },
    { type: "Оптика" },
    { type: "Ремесла" },
    { type: "Цифровые товары" },
    { type: "Оборудование" },
    { type: "Уценка" },
  ];

  chainsPerDayNormal = 200 / 30;

  chainPrice = 24;
  chainsMin = 4;
  chainsMax = 250;

  chainsDefault = 10;

  reviewPrice = REVIEW_PRICE;

  chainsStep = 1;
  viewsStep = 10;

  keywords = YANDEXMARKET_KEYWORDS;

  targetLinkComment = "Укажите тут адрес вашего товара на Яндекс Маркете";
  targetLinkCommentShort = "Укажите ссылку товара";

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      path: "/povedencheskie-faktory-na-yandexmarket",
      rating: 0.7,
      title: "ПФ",
      navigateTitle: "Улучшение ПФ на Яндекс Маркете",
      icon: HEADER_ACTIONS_ICONS.PROMOTION,
      iconForHomePage: HOME_ACTION_ICONS.HOME_ACTIONS,
      status: MARKET_MODE_STATUS.NEW,
      statusForHomePage: MARKET_MODE_STATUS.HIT,
      meta: {
        title:
          "Накрутка поведенческих факторов (ПФ) Яндекс Маркет от 1.9 руб - купить просмотры, отзывы, лайки | Boostclick",
        description: "Улучшайте ПФ на Яндекс Маркете с помощью сервиса Boostclick. С нами вы можете заказать поведенческий фактор.",
      },
      intro: {
        title: "Накрутка на Яндекс Маркете",
        comment: 'Благодаря индивидуальным поведенческим сценариям пользователей',
        points: [
          "Без ботов",
          "Без самовыкупов",
          "Только уникальные пользователи",
          "Соблюдение сроков",
          "Подробные отчеты",
          "Поиск вашего магазина на Яндекс Маркете по ключевым запросам",
        ],
      },
      howItWorks: [
        "Настройте параметры активности в соответствии с конкуренцией",
        "Укажите ссылку на товар для скриншота",
        "Укажите категорию, город и ключевые фразы из карточки товара",
        "После оплаты мы передадим исполнителю скриншот и параметры для поиска",
        "Проверьте ответы и активность, примите работу или отправьте на доработку",
      ],
      stepPhrase1: "Настройте параметры поведенческого фактора (ПФ) Яндекс Маркета",
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: "Продвижение на Яндекс Маркете",
      titleFAQ: "Вопрос-ответ про накрутку поведенческого фактора на Яндекс Маркет",
      faq: YANDEXMARKET_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(
            (action) =>
              action.type !== "MAIN.REVIEW" && !action.type.startsWith("VIEW.")
          ),
          ...ACTIONS.filter((action) => action.type === "MAIN.REVIEW"),
        ];
      })(),
      notice: [
        "* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов.",
        "Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на странице вашего товара.",
        "В первую очередь будут выполняться действия связанные с органическим поиском и изучением товаров, далее добавление в избранное и корзину.",
        "Сложность цепочек и время исполнения зависит от их количества в проекте.",
      ],
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action;
    return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на товар';
  invalidUrlStr = 'Укажите корректную ссылку на товар';
}

const YandexMarket = new YandexMarketClass();
export default YandexMarket;
