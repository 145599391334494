import {
  HEADER_ACTIONS_ICONS,
  ACTION_ICONS,
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market,
  HOME_ACTION_ICONS
} from '../market';

import vkIcon from "assets/images/MarketLogo/vk.svg";
import vkDisabledIcon from "assets/images/MarketLogo/disabled/vk.svg";
import vkLogo from "assets/images/MarketFullLogo/vk.svg";

const VK_FAQ = [
  {
    title: 'Что такое биржа задач Boostclick для ВКонтакте?',
    isH3: false,
    text:
      <div>
        Boostclick — это сервис, который предлагает услуги по привлечению реальных пользователей (не ботов) в вашу группу или на вашу страницу ВКонтакте.
        Мы работаем по принципу биржи задач:
        <br />
        <br />
        <ul>
          <li>Вы создаете задание (например, накрутка подписчиков или лайков).</li>
          <li>Исполнители получают сценарий действий и выполняют задачи, после чего присылают на проверку отчет.</li>
          <li>Если отчет вас устраивает, вы принимаете работу и исполнитель получает оплату. Если нет, то можете сменить исполнителя.</li>
        </ul>
      </div>
  },
  {
    title: 'Преимущества накрутки активных подписчиков во ВКонтакте через Boostclick?',
    isH3: false,
    text:
      <div>
        <ul>
          <li><b>Реальные подписчики</b></li> Мы предлагаем только живых пользователей, которые действительно интересуются вашей тематикой.<br /><br />
          <li><b>Прозрачность</b></li> Вы можете отслеживать динамику роста подписчиков и лайков в реальном времени.<br /><br />
          <li><b>Гарантии</b></li> Мы предоставляем гарантию на качественную накрутку и возможность сменить исполнителя в течение 72 часов после получения отчетов, если работа не устраивает.
        </ul>
      </div>
  },
  {
    title: 'Как создать проект по накрутке подписчиков во ВКонтакте?',
    isH3: false,
    text:
      <p>
        1. Выберите необходимые услуги.
        <br />
        2. Укажите ссылку на вашу страницу или группу ВКонтакте.
        <br />
        3. Оплатите заказ.
        <br />
        4. Следите за динамикой роста подписчиков и лайков в реальном времени.
        <br />
        5. Примите работу исполнителя за 72 часа или смените его.
      </p>
  },
  {
    title: 'Безопасно ли пользоваться накруткой подписчиков в VK от Boostclick?',
    isH3: false,
    text:
      <p>
        Boostclick не использует ботов, что делает нашу накрутку более безопасной.  Пользователи не только подписываются, но и выполняют необходимые действия, что повышает качество привлеченной аудитории.
      </p>
  },
  {
    title: 'Какие преимущества у реальных подписчиков?',
    isH3: false,
    text:
      <div>
        <ul>
          <li><b>Взаимодействие</b></li> Они действительно заинтересованы в вашем контенте и будут ставить лайки, комментировать и делать репосты.<br /><br />
          <li><b>Доверие</b></li> Реальные пользователи делают вашу страницу более достоверной и привлекательной для новых подписчиков.<br /><br />
          <li><b>Повышение узнаваемости</b></li> Реальные пользователи распространяют ваш контент среди своих друзей и знакомых, что помогает увеличить узнаваемость вашего бренда или продукта.
        </ul>
      </div>
  },
  {
    title: 'Как повысить эффективность услуг Boostclick?',
    isH3: false,
    text:
      <div>
        Чтобы максимально увеличить эффективность от накрутки подписчиков в ВК, рекомендуем вам:
        <br />
        <br />
        <ul>
          <li><b>Создавать качественный контент</b></li> Публикуйте интересные и полезные материалы, которые привлекут внимание реальных пользователей.<br /><br />
          <li><b>Взаимодействовать с подписчиками</b></li> Отвечайте на комментарии, проводите конкурсы и акции, чтобы удержать внимание новых подписчиков.<br /><br />
          <li><b>Проверять статистику</b></li> Отслеживайте динамику роста подписчиков и лайков, а также анализируйте эффективность вашего контента.
        </ul>
      </div>
  },
];

const STEP_TWO_TITLE_FAQ = <p>
  Почему при добавлении одного действия в калькуляторе появляются дополнительные действия?
  <br />
  <br />
  Сервис формирует уникальные сценарии для каждого исполнителя, и каждый сценарий начинается для нового исполнителя с перехода на страницу по переданной вами ссылке.
</p>;

const CHAIN_PRICE = 4;
const CHAIN_STEPS = 4;

const ACTIONS = [
  {
    type: 'MAIN.SUBSCRIBE',
    icon: ACTION_ICONS.PEOPLE,
    iconHome: HOME_ACTION_ICONS.HOME_PEOPLE,
    title: 'Активная подписка (человек)',
    titleComment: '+ просмотр постов + просмотр профиля + отчет о работе',
    initialShare: 0.6,
    price: 1,
    activityType: 'Подписка',
  },
  {
    type: 'MAIN.LIKE',
    icon: ACTION_ICONS.LIKE,
    iconHome: HOME_ACTION_ICONS.HOME_LIKE,
    title: 'Поставить лайк / реакцию на пост',
    titleComment: '+ просмотр постов + просмотр профиля + отчет о работе',
    initialShare: 0.2,
    price: 1,
    activityType: 'Лайк',
  },
  {
    type: 'MAIN.WALL',
    icon: ACTION_ICONS.REPOST,
    iconHome: HOME_ACTION_ICONS.HOME_REPOST,
    title: 'Сделать репост на стену',
    initialShare: 0.1,
    price: 1,
    activityType: 'Стена',
  },
  {
    type: 'MAIN.REPOST',
    icon: ACTION_ICONS.REPOST,
    iconHome: HOME_ACTION_ICONS.HOME_REPOST,
    title: 'Переслать запись в сообщения',
    initialShare: 0.1,
    price: 1,
    activityType: 'Репост',
  },

  { type: 'ALWAYS.OPEN', icon: ACTION_ICONS.OPEN, iconHome: HOME_ACTION_ICONS.HOME_OPEN, title: 'Открыть ВКонтакте', initialShare: 1 },
  { type: 'ALWAYS.SEARCH', icon: ACTION_ICONS.SEARCH, iconHome: HOME_ACTION_ICONS.HOME_SEARCH, title: 'Найти страницу / группу', initialShare: 1 },

  { type: 'EXTRA.SEE', icon: ACTION_ICONS.GLASSES, iconHome: HOME_ACTION_ICONS.HOME_GLASSES, title: 'Посмотреть последние 7 постов', initialShare: 0.7 },
  { type: 'EXTRA.PROFILE', icon: ACTION_ICONS.GLASSES, iconHome: HOME_ACTION_ICONS.HOME_GLASSES, title: 'Посмотреть профиль', initialShare: 0.3 },
  { type: 'EXTRA.MEDIA', icon: ACTION_ICONS.EYE, iconHome: HOME_ACTION_ICONS.HOME_GLASSES, title: 'Посмотреть последний клип', titleComment: '(не дольше 1 мин)', initialShare: 0 },

  {
    type: 'MAIN.SURVEY',
    icon: ACTION_ICONS.SURVEY,
    iconHome: HOME_ACTION_ICONS.HOME_SURVEY,
    title: 'Поучаствовать в опросе',
    initialShare: 0,
    activityType: 'Опрос',
  },
];

const VK_KEYWORDS = [
  'VK', 'vk', 'ВК', 'вк', 'Вконтакте', 'вконтакте'
];

class VkClass extends Market {

  id = 'vk';
  host = 'vk.com';
  hostsList = ['vk.com', 'vk.ru', 'vk.me', 'vk.cc', 'vkontakte.ru'];
  url = 'https://vk.com/';

  title = 'ВКонтакте';

  isMarketplace = false;

  icon = vkIcon;
  disabledIcon = vkDisabledIcon;
  logo = vkLogo;
  titlePlaces = 'ВКонтакте';
  statusForHomePage = MARKET_MODE_STATUS.POPULAR;

  categories = [];

  chainsPerDayNormal = 400 / 30;

  chainPrice = CHAIN_PRICE;
  chainsMin = 10;
  chainsMax = 500;

  chainsDefault = 40;

  reviewPrice = 0;

  chainsStep = 10;
  viewsStep = 1;

  keywords = VK_KEYWORDS;

  targetLinkComment = 'Укажите тут адрес вашей страницы или группы во ВКонтакте';
  targetLinkCommentShort = 'Укажите ссылку на страницу';

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      title: 'ПФ',
      navigateTitle: 'Накрутка подписчиков ВК',
      path: '/nakrutka-podpischikov-vk',
      rating: 0.10,
      icon: HEADER_ACTIONS_ICONS.SUBSCRIBE,
      iconForHomePage: HOME_ACTION_ICONS.HOME_ACTIONS,
      status: MARKET_MODE_STATUS.NEW,
      statusForHomePage: MARKET_MODE_STATUS.HIT,
      meta: {
        title: `Купить накрутку подписчиков во ВКонтакте от ${CHAIN_PRICE / CHAIN_STEPS} руб. за действие в сервисе Boostclick`,
        description: 'На бирже Boostclick вы можете заказать живых подписчиков во ВКонтакте',
        canonical: '/nakrutka-podpischikov-vk',
      },
      intro: {
        title: 'Накрутка подписчиков ВКонтакте',
        comment: 'Благодаря индивидуальным поведенческим сценариям пользователей',
        points: [
          'Без ботов',
          'Только уникальные пользователи',
          'Только реальные подписчики',
          'Подробные отчеты',
          'Поиск вашей страницы или группы по ключевым запросам',
        ]
      },
      howItWorks: [
        'Распределите активности',
        'Укажите ссылку на страницу или группу',
        'Сервис создаст задания для исполнителей',
        'Исполнители выполнят задания и пришлют отчеты',
        'Проверьте отчеты, если все верно примите их, если есть ошибки, то смените исполнителя',
      ],
      stepPhrase1: 'Настройте накрутку страницы ВКонтакте, выбрав параметры',
      stepPhrase2: 'Укажите информацию для поиска страницы или группы',
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Накрутка подписчиков во ВКонтакте',
      titleFAQ: "Вопрос-ответ: как накрутить живых подписчиков в ВК",
      faq: VK_FAQ,
      actions: ACTIONS,
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов.',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на вашей странице.',
        'В первую очередь будут выполняться действия связанные с органическим поиском и изучением страницы, далее лайки и подписки.',
        'Сложность цепочек и время исполнения зависит от их количества в проекте.',
      ]
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action; return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на страницу или группу';
  invalidUrlStr = 'Укажите корректную ссылку на страницу или группу';
}

const Vk = new VkClass();
export default Vk;
