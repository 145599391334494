import { observer } from "mobx-react";
import { useState } from "react";

// import DateFilter from "./section/DateFilter/DateFilter";
import { ReferralHistorySection } from "../components/ReferralHistorySection/ReferralHistorySection";
//import Pagination from "components/UI/Pagination/Pagination";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { REFERRAL_INVITED_EMPTY_DESCRIPTION, REFERRAL_INVITED_EMPTY_TITLE } from "../referralConstants";

import "./Invited.scss";

const Invited = observer(({ store }) => {
  const { isMobile } = useMatchMedia();
  const itemsPerPage = 5;
  const [currentPage] = useState(1);

  return (
    <section className="invited">
      <h3 className="invited__title">Приглашенные пользователи</h3>

      <div className="invited__container">
        {/* <div className="invited__sort">
          {!isMobile && <DateFilter store={store} />}
          {!isMobile && (
            <div className="invited__sort_other">
              <div className="invited__sort_other-params">
                Последние 30 дней
              </div>
              <div className="invited__sort_other-params">
                По пользователям
              </div>
              <div className="invited__sort_other-params">
                По дате регистрации
              </div>
            </div>
          )}
        </div> */}

        <div className={`invited__history`}>
          <ReferralHistorySection
            data={store.bonusesStats}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            isMobile={isMobile}
            type="invited"
            emptyTitle={REFERRAL_INVITED_EMPTY_TITLE}
            emptyDescription={REFERRAL_INVITED_EMPTY_DESCRIPTION}
          />
        </div>
      </div>
      {/* {filteredAndSortedData.length > itemsPerPage && (
        <Pagination
          totalItems={filteredAndSortedData.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isMobile={isMobile}
        />
      )} */}
    </section>
  );
});

export default Invited;
