import {
  HEADER_ACTIONS_ICONS,
  ACTION_ICONS,
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market,
  HOME_ACTION_ICONS
} from '../market';

import telegramIcon from "assets/images/MarketLogo/telegram.svg";
import telegramDisabledIcon from "assets/images/MarketLogo/disabled/telegram.svg";
import telegramLogo from "assets/images/MarketFullLogo/telegram.svg";

const TELEGRAM_FAQ = [
  {
    title: 'Может ли понизиться статистика на канале после накрутки?',
    isH3: false,
    text:
      <p>
        Нет, так как увеличение показателей происходит равномерно.
      </p>
  },
  {
    title: 'Могут ли забанить мой канал?',
    isH3: false,
    text:
      <p>
        Канал в Телеграм не забанят, потому что активность проявляют живые пользователи.
      </p>
  },
  {
    title: 'Будет ли видна накрутка для рекламодателей через сервисы статистики?',
    isH3: true,
    text:
      <p>
        Накрутка не заметна, прирост всех показателей происходит равномерно, без резких скачков и спадов.
        График статистики будет плавно расти вверх.
      </p>
  },
  {
    title: 'Сколько времени займет выполнение проекта?',
    isH3: false,
    text:
      <p>
        Время выполнения проекта рассчитывается индивидуально под ваш запрос, чтобы прирост показателей был равномерным.
      </p>
  },
  {
    title: 'Через какое время начнет выполняться проект?',
    isH3: false,
    text:
      <p>
        Сразу после активации заказа.
      </p>
  },
  {
    title: 'Не отпишутся ли подписчики?',
    isH3: false,
    text:
      <p>
        Минимальная отписка в Телеграмм возможна, не более 5%.
      </p>
  },
  {
    title: 'Гарантии и безопасность',
    isH3: false,
    text:
      <p>
        Накрутка поведенческого фактора является вспомогательной и дополнительной мерой в рекламных кампаниях.
        <br />
        Мы гарантируем, что исполнители Boostclick реальные люди, которые получают точные инструкции для безопасной накрутки ПФ.
        <br />
        <br />
        Сервис дает возможность сделать накрутку равномерной благодаря индивидуальной формуле, рассчитанной под ваш запрос.
        <br />
        Для соблюдения органичного поиска, исполнители не получают URL ссылки на продвигаемые страницы, что гарантирует органический поиск.
        <br />
        <br />
        Мы запрещаем исполнителям делать скриншоты, что исключает фиксирование Яндекс Метрикой и Вебвизором сам факт скриншота.
        <br />
        Для проверки мы используем различные микрозадачи, которые можно выполнить только находясь на продвигаемой странице.
      </p>
  },
];

const STEP_TWO_TITLE_FAQ = <p>
  Почему при добавлении одного действия в калькуляторе появляются дополнительные действия?
  <br />
  <br />
  Сервис формирует уникальные сценарии для каждого исполнителя, и каждый сценарий начинается для нового исполнителя с перехода на канал по переданной вами ссылке.
</p>;

const CHAIN_PRICE = 6;
const CHAIN_STEPS = 4;

const STEP_PRICE = Number((CHAIN_PRICE / CHAIN_STEPS).toFixed(1));

const ACTIONS = [
  {
    type: 'MAIN.SUBSCRIBE',
    icon: ACTION_ICONS.PEOPLE,
    iconHome: HOME_ACTION_ICONS.HOME_PEOPLE,
    title: 'Активная подписка (человек)',
    titleComment: '+\u00a0просмотр\u00a0постов +\u00a0просмотр\u00a0профиля +\u00a0отчет\u00a0о\u00a0работе',
    initialShare: 0.9,
    price: STEP_PRICE,
    activityType: 'Подписка',
  },
  {
    type: 'MAIN.LIKE',
    icon: ACTION_ICONS.LIKE,
    iconHome: HOME_ACTION_ICONS.HOME_LIKE,
    title: 'Поставить лайк / реакцию на пост',
    titleComment: '+\u00a0просмотр\u00a0постов +\u00a0просмотр\u00a0профиля +\u00a0отчет\u00a0о\u00a0работе',
    initialShare: 0,
    activityType: 'Лайк',
  },
  {
    type: 'MAIN.REPOST',
    icon: ACTION_ICONS.MESSAGE,
    iconHome: HOME_ACTION_ICONS.HOME_REPOST,
    title: 'Сделать репост поста в ВК',
    initialShare: 0.1,
    activityType: 'Репост',
  },

  { type: 'ALWAYS.OPEN', icon: ACTION_ICONS.OPEN, iconHome: HOME_ACTION_ICONS.HOME_OPEN, title: 'Открыть Telegram', initialShare: 1 },
  { type: 'ALWAYS.SEARCH', icon: ACTION_ICONS.SEARCH, iconHome: HOME_ACTION_ICONS.HOME_SEARCH, title: 'Найти канал / группу', initialShare: 1, price: STEP_PRICE },

  { type: 'EXTRA.SEE', icon: ACTION_ICONS.GLASSES, iconHome: HOME_ACTION_ICONS.HOME_GLASSES, title: 'Посмотреть последние 7 постов', initialShare: 0.7, price: STEP_PRICE },
  { type: 'EXTRA.PROFILE', icon: ACTION_ICONS.CLICK, iconHome: HOME_ACTION_ICONS.HOME_CLICK, title: 'Посмотреть профиль', initialShare: 0.3, price: STEP_PRICE },

  {
    type: 'MAIN.SURVEY',
    icon: ACTION_ICONS.CLICK,
    iconHome: HOME_ACTION_ICONS.HOME_CLICK,
    title: 'Поучаствовать в опросе',
    initialShare: 0,
    activityType: 'Опрос',
  },
];

const TELEGRAM_KEYWORDS = [
  'Telegram', 'telegram', 'Телеграм', 'телеграм', 'tg', 'тг',
];

class TelegramClass extends Market {

  id = 'telegram';
  host = 't.me';
  url = 'https://t.me/';

  title = 'Telegram';

  isMarketplace = false;

  icon = telegramIcon;
  disabledIcon = telegramDisabledIcon;
  logo = telegramLogo;
  titlePlaces = 'Телеграм';
  statusForHomePage = MARKET_MODE_STATUS.POPULAR;

  categories = [];

  chainsPerDayNormal = 400 / 30;

  chainPrice = CHAIN_PRICE;
  chainsMin = 10;
  chainsMax = 500;

  chainsDefault = 30;

  reviewPrice = 0;

  chainsStep = 10;
  viewsStep = 1;

  keywords = TELEGRAM_KEYWORDS;

  targetLinkComment = 'Укажите тут адрес вашего канала в Телеграм';
  targetLinkCommentShort = 'Укажите ссылку на канал';

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      title: 'ПФ',
      navigateTitle: 'Накрутка подписчиков Телеграм',
      path: '/nakrutka-podpischikov-telegram',
      rating: 0.20,
      icon: HEADER_ACTIONS_ICONS.SUBSCRIBE,
      iconForHomePage: HOME_ACTION_ICONS.HOME_ACTIONS,
      status: MARKET_MODE_STATUS.REGULAR,
      statusForHomePage: MARKET_MODE_STATUS.HIT,
      meta: {
        title: `Купить накрутку подписчиков в Telegram от ${CHAIN_PRICE / CHAIN_STEPS} руб. за действие в сервисе Boostclick`,
        description: 'На бирже Boostclick вы можете заказать живых подписчиков в Телеграм',
      },
      intro: {
        title: 'Накрутка подписчиков Телеграм',
        comment: 'Благодаря индивидуальным поведенческим сценариям пользователей',
        points: [
          'Без ботов',
          'Только уникальные пользователи',
          'Только реальные подписчики',
          'Подробные отчеты',
          'Поиск вашего аккаунта по ключевым запросам',
        ]
      },
      howItWorks: [
        'Распределите активности',
        'Укажите ссылку на Телеграм канал / чат',
        'Сервис создаст задания для исполнителей',
        'Исполнители выполнят задания и пришлют отчеты',
        'Проверьте отчеты, если все верно примите их, если есть ошибки, то смените исполнителя',
      ],
      stepPhrase1: 'Настройте количество подписчиков в Телеграм и параметры',
      stepPhrase2: 'Укажите информацию для поиска канала',
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Накрутка подписчиков Телеграм',
      titleFAQ: "Вопрос-ответ",
      faq: TELEGRAM_FAQ,
      actions: ACTIONS,
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов.',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей в вашем канале.',
        'В первую очередь будут выполняться действия связанные с органическим поиском и изучением канала, далее лайки и подписки. В последнюю очередь делаются действия с чатом.',
        'Сложность цепочек и время исполнения зависит от их количества в проекте.',
      ]
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action; return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на канал или группу';
  invalidUrlStr = 'Укажите корректную ссылку на канал или группу';
}

const Telegram = new TelegramClass();
export default Telegram;
