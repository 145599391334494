import Carousel from "components/UI/Carousel/Carousel";

import "./AdvantagesSection.scss";

const countPerformers = 49000;

const AdvantagesSection = ({ slides, isMobile }) => (
  <section className="main__advantages">
    <div className="main__advantages_small">
      <div className="main__advantages_small-left">
        <p>+ {countPerformers.toLocaleString()} исполнителей</p>
        {isMobile && <span>Задачи, которые вы ставите на бирже, выполняют реальные, проверенные пользователи</span>}
      </div>
      <div className="main__advantages_small-right">
        <div className="main__advantages_small-right-icon" />
        <p>И ни одного бота</p>
      </div>
      <svg className="main__advantages-dino" xmlns="http://www.w3.org/2000/svg" width="161" height="150" viewBox="0 0 161 150" fill="none">
        <path d="M93.4749 108.434V100.085H101.893V91.7502H110.311V83.4017H127.146L135.564 83.4017L135.564 75.0667V66.7182L127.146 66.7182L127.143 75.0667H118.725V66.7182V58.3832H127.143H135.564H143.989V50.0505H135.564H127.143V41.702H135.564H143.989H152.405H160.812V33.367V25.0185V16.6835V8.33497H152.4V0L143.984 0L135.559 0L127.139 0L118.721 0L110.307 0L101.889 0L93.4749 0V8.33497H85.0567V16.6835V25.0185V33.367V41.702V50.0505H76.6453V58.3855H68.2202V66.7205H59.8087V75.069H51.3836V83.2479V83.4017H42.9722H34.7023H34.5471H26.2772V75.0667H17.8658V66.7182H9.44071V58.3832V50.0505H1.0293L1.0293 58.3855L1.0293 66.7205L1.0293 75.069L1.0293 83.404H9.44071V91.7525H17.8658V100.087H26.2772V108.436H34.5471V116.631H42.9722V116.771V124.966H51.3836H51.4316L51.4316 133.316V141.651V150H59.843H68.2681V141.651H59.843V133.316L59.843 125.135H68.2224V116.787H76.6475V125.135H85.059V133.316V141.651H85.011V150H93.4361H101.848V141.651H93.4749V133.316V125.135V124.963V116.785V108.45M101.893 33.367V25.0185H110.311V33.367H101.893Z" fill="#1E1E1E"/>
        <path d="M93.4749 108.434V100.085H101.893V91.7502H110.311V83.4017H127.146L135.564 83.4017L135.564 75.0667V66.7182L127.146 66.7182L127.143 75.0667H118.725V66.7182V58.3832H127.143H135.564H143.989V50.0505H135.564H127.143V41.702H135.564H143.989H152.405H160.812V33.367V25.0185V16.6835V8.33497H152.4V0L143.984 0L135.559 0L127.139 0L118.721 0L110.307 0L101.889 0L93.4749 0V8.33497H85.0567V16.6835V25.0185V33.367V41.702V50.0505H76.6453V58.3855H68.2202V66.7205H59.8087V75.069H51.3836V83.2479V83.4017H42.9722H34.7023H34.5471H26.2772V75.0667H17.8658V66.7182H9.44071V58.3832V50.0505H1.0293L1.0293 58.3855L1.0293 66.7205L1.0293 75.069L1.0293 83.404H9.44071V91.7525H17.8658V100.087H26.2772V108.436H34.5471V116.631H42.9722V116.771V124.966H51.3836H51.4316L51.4316 133.316V141.651V150H59.843H68.2681V141.651H59.843V133.316L59.843 125.135H68.2224V116.787H76.6475V125.135H85.059V133.316V141.651H85.011V150H93.4361H101.848V141.651H93.4749V133.316V125.135V124.963V116.785V108.45M101.893 33.367V25.0185H110.311V33.367H101.893Z" fill="black" fillOpacity="0.2"/>
        <path d="M101.895 25.0195V33.3681H110.313V25.0195H101.895Z" fill="white"/>
        <path d="M118.927 68.3036V61.6965V58.393H95.8024V51.7859H84.2401V46.8306H80.9365H77.633V43.5271H80.9365V36.92H77.633V33.6165H74.3295V28.6612H64.4189V25.3576H56.16V20.4023L23.1247 20.4023V25.3576H13.2141V27.0094V30.3129H4.9553V35.2682H0L0 38.5718H16.5177V35.2682H19.8212H36.3388H42.9459V40.2235H56.16V45.1788H62.7671V48.4824H66.0706V51.7859H69.3742V55.0894H75.9812V58.393H79.2848V61.6965H89.1954V68.3036H118.927Z" fill="#FFF862"/>
        <path d="M19.8248 41.8786H16.5213H13.2178V38.575H16.5213V35.2715H19.8248H42.9496V40.2268H56.1637V45.1821H62.7708V48.4856H49.5566H46.2531V51.7891H36.3425H23.1284V48.4856H19.8248V45.1821V41.8786Z" fill="#DFBB2A"/>
      </svg>
    </div>

    <div className="main__advantages_medium">
      <p>Отправим чек и&nbsp;договор {/* <br /> Работаем {isMobile ? "с юр." : "с юридическими"} лицами */}</p>
      <span>Безопасная оплата через Робокассу</span>
      <div className="main__advantages_medium-bg" />
    </div>
    
    <div className="main__advantages_big">
      <div className="main__advantages_big-description">
        <p>Только вы решаете, <br /> когда задача завершена</p>
        <p className="main__advantages_big-description-text">Вы проверяете отчеты, <br /> а учет проектов всегда перед вами!</p>
      </div>
      {!isMobile && <Carousel slides={slides} />}
    </div>
  </section>
);

export default AdvantagesSection;
