import validator from 'validator';

import busket from "assets/images/activities/busket.svg";
import click from "assets/images/activities/click.svg";
import dislike from "assets/images/activities/dislike.svg";
import eye from "assets/images/activities/eye.svg";
import glasses from "assets/images/activities/glasses.svg";
import heart from "assets/images/activities/heart.svg";
import like from "assets/images/activities/like.svg";
import message from "assets/images/activities/message.svg";
import open from "assets/images/activities/open.svg";
import search from "assets/images/activities/search.svg";
import subscribe from "assets/images/activities/subscribe.svg";
import time from "assets/images/activities/time.svg";
import people from "assets/images/activities/people.svg";
import phone from "assets/images/activities/phone.svg";
import repost from "assets/images/activities/repost.svg";
import survey from "assets/images/activities/survey.svg";
import star from "assets/images/activities/star.svg";
import action from "assets/images/activities/action.svg";
import homeBusket from 'assets/images/activities/onhomepage/homeBusket.svg';
import homeClick from 'assets/images/activities/onhomepage/homeClick.svg';
import homeDislike from 'assets/images/activities/onhomepage/homeDislike.svg';
import homeEye from 'assets/images/activities/onhomepage/homeEye.svg';
import homeGlasses from 'assets/images/activities/onhomepage/homeGlasses.svg';
import homeHeart from 'assets/images/activities/onhomepage/homeHeart.svg';
import homeLike from 'assets/images/activities/onhomepage/homeLike.svg';
import homeMessage from 'assets/images/activities/onhomepage/homeMessage.svg';
import homeReviews from 'assets/images/activities/onhomepage/homeReviews.svg';
import homeOpen from 'assets/images/activities/onhomepage/homeOpen.svg';
import homeSearch from 'assets/images/activities/onhomepage/homeSearch.svg';
import homeTime from 'assets/images/activities/onhomepage/homeTime.svg';
import homePeople from 'assets/images/activities/onhomepage/homePeople.svg';
import homeRepost from 'assets/images/activities/onhomepage/homeRepost.svg';
import homeStar from 'assets/images/activities/onhomepage/homeStar.svg';
import homeAction from 'assets/images/activities/onhomepage/homeAction.svg';

import lightningBlack from "assets/images/activities/lightningBlack.svg";
import messageBlack from "assets/images/activities/messageBlack.svg";
import subscribeBlack from "assets/images/activities/subscribeBlack.svg";
import eyeBlack from "assets/images/activities/eyeBlack.svg";

export const MARKET_MODES = {
  CHAINS: 'chains',
  LIKES: 'likes',
  FOLLOWERS: 'followers',
  VIEWS: 'views',
  COMMENTS: 'comments',
  REVIEWS: 'reviews',
};

export const MARKET_MODE_STATUS = {
  REGULAR: 'regular',
  NEW: 'new',
  BETA: 'beta',
  EXCLUSIVE: 'exclusive',

  // for home page
  POPULAR: 'popular',
  SOON: 'soon',
  HIT: 'hit',
};

export const ACTION_ICONS = {
  BUSKET: busket,
  CLICK: click,
  DISLIKE: dislike,
  EYE: eye,
  GLASSES: glasses,
  HEART: heart,
  LIKE: like,
  MESSAGE: message,
  OPEN: open,
  SEARCH: search,
  SUBSCRIBE: subscribe,
  TIME: time,
  PEOPLE: people,
  PHONE: phone,
  REPOST: repost,
  SURVEY: survey,
  STAR: star,
  ACTIONS: action,
};

export const HOME_ACTION_ICONS = {
  HOME_BUSKET: homeBusket,
  HOME_CLICK: homeClick,
  HOME_DISLIKE: homeDislike,
  HOME_EYE: homeEye,
  HOME_GLASSES: homeGlasses,
  HOME_HEART: homeHeart,
  HOME_LIKE: homeLike,
  HOME_MESSAGE: homeMessage,
  HOME_REVIEWS: homeReviews,
  HOME_OPEN: homeOpen,
  HOME_SEARCH: homeSearch,
  HOME_SUBSCRIBE: subscribe,
  HOME_TIME: homeTime,
  HOME_PEOPLE: homePeople,
  HOME_PHONE: phone,
  HOME_REPOST: homeRepost,
  HOME_SURVEY: survey,
  HOME_STAR: homeStar,
  HOME_ACTIONS: homeAction,
};

export const HEADER_ACTIONS_ICONS = {
  PROMOTION: lightningBlack,
  MESSAGE: messageBlack,
  SUBSCRIBE: subscribeBlack,
  EYE: eyeBlack,
};

/**
 * Типы действий:
 * - ALWAYS: действие всегда привязывается к цепочке
 * - MAIN: основное действие в данном режиме, участвует в основнм распределении
 * - EXTRA: дополнительное действие в данном режиме, участвует в дополнительном распределении
 */
export const ACTION_TYPES = {
  ALWAYS: 'always',
  MAIN: 'main',
  EXTRA: 'extra',
};

/**
 * @typedef Action
 * @property {string} type Идентификатор действия. Должен быть заменен на `id`.
 * @property {string} trueType Тип действия из `ACTION_TYPES`.
 * Один и тот же идентификатор в разных режимах может иметь разные типы.
 */

/**
 * @typedef MarketMode
 * @property {string} id Идентификатор маркета из `MARKET_MODES`.
 * @property {Action[]} actions Действия в этом режиме.
 */

export class Market {

  /**
   * @param {string} url
   * @returns {string|null} Сообщение об ошибке.
   */
  validateTargetUrl(url) {
    if (!url)
      return this.emptyUrlStr ?? 'Укажите ссылку на продвигаемый ресурс';

    if (!validator.isURL(url))
      return this.invalidUrlStr ?? 'Укажите корректную ссылку на продвигаемый ресурс';

    if (this.isSeo)
      return null;

    let hostsList = this.hostsList ?? [];
    if (this.host)
      hostsList.push(this.host);
    if (hostsList.length) {
      if (!hostsList.some(host => url.includes(host)))
        return this.invalidUrlStr ?? 'Укажите корректную ссылку на продвигаемый ресурс';
    }

    return null;
  }
}
