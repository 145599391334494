import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import './StatisticsSection.scss';

const StatisticsSection = ({ isMobile }) => {
  const [number, setNumber] = useState(0);
  const { ref: statisticsRef, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (!inView) return;

    const targetNumber = 598125;
    const duration = 2000;
    const interval = 50;
    const steps = duration / interval;
    const increment = Math.ceil(targetNumber / steps);

    const timer = setInterval(() => {
      setNumber((prevNumber) => {
        const nextNumber = prevNumber + increment;
        if (nextNumber >= targetNumber) {
          clearInterval(timer);
          return targetNumber;
        }
        return nextNumber;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [inView]);

  const renderDigits = (num) => { return num.toString().split('').map((digit, index) => <div key={index} className="statistics__digit">{digit}</div>); };

  return (
    <section ref={statisticsRef} className="statistics">
      <div className="statistics__digits-container">
        <p>Завершено</p>
        <div className="statistics__digits-wrapper">{renderDigits(number)}</div>
        {!isMobile && <p>заданий</p>}
      </div>
      <p>{isMobile && <span>заданий</span>} от 4k пользователей биржи {!isMobile && <br />} Boostclick</p>
    </section>
  );
};

export default StatisticsSection;