import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import BannerStep from "components/BannerStep/BannerStep";
import { SettingsStep } from "./SettingsStep/SettingsStep";
import { Step2 } from "./Step2/Step2";
import { SettingsSeoStep } from "./SettingsSeoStep/SettingsSeoStep";
// import MarketSelection from "./Step2/componentsStep2/MarketSelection/MarketSelection";
import HowItWorks from "components/HowItWorks/HowItWorks";
import IntroWithPoints from "components/IntroWithPoints/IntroWithPoints";
import Answers from "components/Answers/Answers";

import { useMatchMedia } from "utils/hooks/use-match-media";

import AppStore from 'AppStore';
import popupStore from "components/Popup/PopupStore";

import { HOW_IT_WORKS_TITLE, STEP1_TITLE_CREATE_TASK, STEP2_TITLE_LOOKING_PERFORMER, STEP2_DESCRIPTION_LOOKING_PERFORMER, STEP3_TITLE_CHECK_PAY_REPORT, STEP3_DESCRIPTION_CHECK_PAY_REPORT } from "./marketConstants";
import { create, search, report, step1, step2, step3 } from "./marketConstants";

import "./Market.scss";

const promoData = [
  {
    icon: { src: create, alt: "Иконка шага 1" },
    step: { src: step1, alt: "Номер шага 01" },
    title: STEP1_TITLE_CREATE_TASK,
    text: 
    <>
      Она попадает в <Link  to={AppStore.isAuth ? "/projects" : "/signin"}  className="how-it-works__advices-link">Черновики</Link>. 
      Там вы можете оплатить ее, сперва пополнив счет в 
      {AppStore.isAuth ? <button className="how-it-works__advices-button" type="button" aria-label="Открыть пополнение баланса" onClick={() => popupStore.open("paymodal")}>Личном кабинете</button>
      : <Link to="/signin" className="how-it-works__advices-link">Личном кабинете</Link>}
    </>
  },
  {
    icon: { src: search, alt: "Иконка шага 1" },
    step: { src: step2, alt: "Номер шага 01" },
    title: STEP2_TITLE_LOOKING_PERFORMER,
    text: STEP2_DESCRIPTION_LOOKING_PERFORMER,
  },
  {
    icon: { src: report, alt: "Иконка шага 1" },
    step: { src: step3, alt: "Номер шага 01" },
    title: STEP3_TITLE_CHECK_PAY_REPORT,
    text: STEP3_DESCRIPTION_CHECK_PAY_REPORT,
  },
];

const Market = observer(() => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { isMobile } = useMatchMedia();
  const location = useLocation();
  const step1Ref = useRef();
  const step2Ref = useRef();
  const lastHash = useRef("");
  const linkStep1Ref = useRef(null);
  const categoryStep1Ref = useRef(null);
  const cityStep1Ref = useRef(null);
  const searchPhrasesStep1Ref = useRef(null);
  const screenshotStep1Ref = useRef(null);
  const reviewStep2Ref = useRef(null);
  const commentStep2Ref = useRef(null);
  const brandPhrasesStep1Ref = useRef(null);

  const calculator = AppStore.currentMarketStore.currentCalculator;
  const { mode } = calculator;
  const meta = mode.meta;
  const marketPath = mode.path;
  const isSettingsSeo = AppStore.currentMarketStore.calculators[0].market.isSeo;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const scrollToError = (errorRef) => {
    if (errorRef.current) {
      const headerHeight = 100;
      const rect = errorRef.current.getBoundingClientRect();
      const offsetTop = rect.top + window.scrollY - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.hash) lastHash.current = location.hash.slice(1);

    if (!lastHash.current || !document.getElementById(lastHash.current))
      return;

    setTimeout(() => {
      document
        .getElementById(lastHash.current)
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      lastHash.current = "";
    }, 0);
  }, [location]);

  return (
    <main className="market">
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="robots" content="all" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <div className="market__inner">
        {!AppStore.isAuth &&
          <>
            <IntroWithPoints {...mode.intro} type={mode.id} marketPath={marketPath} hiddenContent={mode.hiddenContent} />
            {isMobile && (
              <>
                <div className="how-it-works__button-open">
                  <p className={`how-it-works__button-open-text ${isExpanded ? "other-color" : ""}`} onClick={toggleExpanded}>🤔 Как работает биржа заданий?</p>
                  {isExpanded && <HowItWorks isExpanded={isExpanded} toggleExpanded={toggleExpanded} title={HOW_IT_WORKS_TITLE} stepData={promoData} additionalContent={<ul className="how-it-works__advice">{mode.howItWorks.map((advice, i) => <li key={i} className="how-it-works__advice-item">{advice}</li>)}</ul>} />}
                </div>
              </>
            )}
          </>
        }

        {!isMobile && <HowItWorks isExpanded={isExpanded} toggleExpanded={toggleExpanded} title={HOW_IT_WORKS_TITLE} stepData={promoData} additionalContent={<ul className="how-it-works__advice">{mode.howItWorks.map((advice, i) => <li key={i} className="how-it-works__advice-item">{advice}</li>)}</ul>} />}

        <div className="market__interactive">
          {/* <div ref={step2Ref}><BannerStep banner="banner-first" stepPhrase1={mode.stepPhrase1} isMobile={isMobile} /></div> */}
          <div ref={step2Ref} className="market__interactive-title">
            <p>Шаг 1<span>/2:</span></p>
            <h2 className="market__interactive-title__steps-total">{mode.stepPhrase1}</h2>
          </div>
          <div>
            {/* <MarketSelection store={AppStore.currentMarketStore} /> */}
            <Step2
              key={isMobile ? "mobile-step2" : "desktop-step2"}
              store={AppStore.currentMarketStore}
              nextStep={step1Ref}
              reviewRef={reviewStep2Ref}
              commentRef={commentStep2Ref}
              scrollToError={scrollToError}
            />
          </div>
          <div ref={step1Ref}><BannerStep banner="banner-second-step" stepPhrase2={mode.stepPhrase2} isMobile={isMobile} /></div>
          {isSettingsSeo ?
            <SettingsSeoStep
              key={isMobile ? "mobile-step1" : "desktop-step1"}
              store={AppStore.currentMarketStore}
              step1Ref={step2Ref}
              linkRef={linkStep1Ref}
              cityRef={cityStep1Ref}
              searchPhrasesRef={searchPhrasesStep1Ref}
              screenshotRef={screenshotStep1Ref}
              brandPhrasesRef={brandPhrasesStep1Ref}
              scrollToError={scrollToError}
            />
            :
            <SettingsStep
              key={isMobile ? "mobile-step1" : "desktop-step1"}
              store={AppStore.currentMarketStore}
              step1Ref={step2Ref}
              linkRef={linkStep1Ref}
              categoryRef={categoryStep1Ref}
              cityRef={cityStep1Ref}
              searchPhrasesRef={searchPhrasesStep1Ref}
              screenshotRef={screenshotStep1Ref}
              reviewRef={reviewStep2Ref}
              commentRef={commentStep2Ref}
              scrollToError={scrollToError}
            />
          }
        </div>

        <Answers title={mode.titleFAQ} questions={mode.faq} />
      </div>
    </main>
  );
});

export default Market;
