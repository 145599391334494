import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import Badges from "components/UI/Badges/Badges";

import "./ActionLink.scss";

const ActionLink = observer(({ action, path, isAnimated, marketTitle, status, isMobile, target = "_self", }) => {

  return (
    <Link className={`main__action-link ${isAnimated ? "animated" : ""}`} to={path} target={target} onClick={() => window.scrollTo(0, 0)}>
      <div className="main__action-link-info">
        <div className="main__action-link-icon" style={{ backgroundImage: `url(${action.icon})` }} />
        <div className="main__action-link-title">
          <p className="main__action-link-label">{action.title}</p>
          {!isMobile && <p className="main__action-link-sublabel"> {!action.title.includes(marketTitle) ? marketTitle : ""} {action.titleComment}</p>}
        </div>
      </div>
      {isMobile && <div className="main__action-link-container"><p className="main__action-link-sublabel">{!action.title.includes(marketTitle) ? marketTitle : ""} {action.titleComment}</p></div>}
      {status && <Badges status={status} />}
    </Link>
  );
});

export default ActionLink;
